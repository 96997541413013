"use client";

import { GoogleOAuthProvider as GoogleOAuthProviderBase } from "@react-oauth/google";
import { PropsWithChildren } from "react";

// We are using this only in the login page. Evaluate to move provider within Login component in CC
export function GoogleOAuthProvider({ children }: PropsWithChildren) {
  return (
    <GoogleOAuthProviderBase
      clientId={process.env.NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID as string}
    >
      {children}
    </GoogleOAuthProviderBase>
  );
}
