"use client";

import { createTheme } from "@mui/material/styles";
import { theme } from "./src/app/styles/theme";

export { default as GlobalStyles } from "@mui/material/GlobalStyles";
export {
  css,
  styled,
  ThemeProvider as MaterialThemeProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";

export { CacheProvider, Global, ThemeProvider } from "@emotion/react";
export { default as createCache } from "@emotion/cache";
export { withTransientProps } from "./src/lib/emotion";

export type { Theme } from "./src/app/styles/theme";

export const materialTheme = createTheme({
  ...theme,
  // 6 * 2 except for 20 which is needed to match our designs
  spacing: [0, 6, 12, 20, 24, 48, 96, 192, 384, 768, 1536],
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
