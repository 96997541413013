"use client";

import {
  css,
  GlobalStyles as Global,
  Theme,
} from "@bigspring/core-components/styles";

const globalStyles = (theme: Theme) => css`
  :root {
    --bigspring-primary-font: "Roboto", sans-serif;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }

  *::-webkit-scrollbar {
    width: 0.6em;
  }
  *::-webkit-scrollbar-track {
    background-color: transparent;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: slategrey;
  }

  a {
    text-decoration: none;
  }

  html {
    box-sizing: border-box;
    font-feature-settings: "kern", "liga", "clig" !important;
    font-kerning: auto !important;
    -webkit-font-smoothing: antialiased !important;
    font-variant-ligatures: common-ligatures contextual !important;
    text-rendering: optimizeLegibility !important;
  }

  body {
    overscroll-behavior-x: none;
    color: rgb(73, 64, 53) !important;
    font-family: sans-serif;
    font-weight: 400 !important;
    margin: 0;
    padding: 0;
  }

  #internal-body {
    scroll-behavior: smooth;
  }

  html.sans body {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Helvetica, Arial, sans-serif;
  }

  b,
  strong {
    font-weight: 700;
  }

  em {
    line-height: 0;
  }

  .SnackbarItem-nowrap > div {
    flex-wrap: nowrap;
  }

  .white-tooltip {
    background-color: white !important;
    border-radius: 8px !important;
    border: 1px solid #e4e4e4 !important;
    padding: 20px !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06) !important;
    color: #494947 !important;
    &.mobile {
      display: none;
      ${theme.breakpoint.maxWidth.md`
        display: block;
      `};
    }
    &.desktop {
      display: none;
      ${theme.breakpoint.minWidth.md`
        display: block;
      `};
    }
    .MuiTooltip-arrow {
      color: white !important;
      &::before {
        border: 1px solid #e4e4e4 !important;
      }
    }
  }

  .transparent-tooltip {
    background-color: transparent !important;
    color: unset !important;
  }
`;

export function GlobalStyles() {
  return <Global styles={globalStyles} />;
}
