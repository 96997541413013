import { parse, serialize } from "cookie";

export const maintenanceKeyName = "maintenanceMode";

export function isMaintenanceMode() {
  if (typeof window === "undefined" || typeof document === "undefined") {
    return false;
  }

  return parse(document.cookie)[maintenanceKeyName] ? true : false;
}

/**
 * TODO: This may work better if we perform the Me request in a Server Component and set this cookie
 * there so users see the maintenance page ASAP
 */
export function setMaintenanceMode(enabled: boolean) {
  if (enabled) {
    enableMaintenanceMode();
  } else {
    disableMaintenanceMode();
  }
}

function enableMaintenanceMode() {
  if (typeof window === "undefined" || typeof document === "undefined") {
    return null;
  }

  const cookie = serialize(maintenanceKeyName, JSON.stringify(true), {
    domain: window.location.hostname,
    path: "/",
    maxAge: 60 * 60 * 24 * 365,
  });
  document.cookie = cookie;
}

function disableMaintenanceMode() {
  if (typeof window === "undefined" || typeof document === "undefined") {
    return null;
  }

  const cookie = serialize(maintenanceKeyName, "", {
    domain: window.location.hostname,
    path: "/",
    maxAge: 0,
  });
  document.cookie = cookie;
}
