"use client";

import { useState } from "react";
import { useServerInsertedHTML } from "next/navigation";
import { Notification } from "@bigspring/core-components";
import {
  CacheProvider,
  createCache,
  materialTheme,
  MaterialThemeProvider,
  MATERIAL_THEME_ID,
} from "@bigspring/core-components/styles";

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [{ cache, flush }] = useState(() => {
    const cache = createCache({ key: "bigspring-styles" });
    cache.compat = true;
    const prevInsert = cache.insert;
    let inserted: string[] = [];
    cache.insert = (...args: any[]) => {
      const serialized = args[1];
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push(serialized.name);
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return prevInsert(...args);
    };

    const flush = () => {
      const prevInserted = inserted;
      inserted = [];

      return prevInserted;
    };

    return { cache, flush };
  });

  useServerInsertedHTML(() => {
    const names = flush();
    if (names.length === 0) {
      return null;
    }

    let styles = "";
    for (const name of names) {
      styles += cache.inserted[name];
    }

    return (
      <style
        key={cache.key}
        data-emotion={`${cache.key} ${names.join(" ")}`}
        dangerouslySetInnerHTML={{
          __html: styles,
        }}
      />
    );
  });

  return (
    <CacheProvider value={cache}>
      <MaterialThemeProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
        <Notification
          autoHideDuration={10000}
          maxSnack={5}
          position={{ horizontal: "center" }}
        >
          {children}
        </Notification>
      </MaterialThemeProvider>
    </CacheProvider>
  );
}
