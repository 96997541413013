import(/* webpackMode: "eager" */ "/bigspring/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/bigspring/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\"],\"preload\":true,\"variable\":\"--bigspring-primary-font\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalStyles"] */ "/bigspring/packages/app/app/globalStyles.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CustomRouterProvider"] */ "/bigspring/packages/app/components/shared/CustomRouter/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloProvider"] */ "/bigspring/packages/app/config/apolloClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleOAuthProvider"] */ "/bigspring/packages/app/lib/googleOAuth.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/bigspring/packages/app/lib/theme.tsx");
