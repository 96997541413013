"use client";

import { ApolloLink, HttpLink, NormalizedCacheObject } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloNextAppProvider,
  ApolloClient,
  InMemoryCache,
  SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support";
import { fetchNewTokenAndRetry, getCompanyProfileId } from "@utils/auth";
import { setMaintenanceMode } from "@utils/maintenanceMode";
import { SKIP_AUTHENTICATION_WHITELIST } from "./whitelist";

const apolloClient: ApolloClient<NormalizedCacheObject> = makeClient();

function makeClient(ssrCompanyProfileId?: string) {
  const httpLink = new HttpLink({
    uri: `${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql`,
    credentials: "include",
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.debug(
            `[Apollo GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );

        if (
          graphQLErrors.some(
            (e) =>
              e.message.includes("Session is expired") ||
              e.message.includes("Access denied")
          )
        ) {
          console.debug(`[Apollo Session expired], graphql error`);

          return fetchNewTokenAndRetry(apolloClient, operation, forward);
        }
        // if ()
      }

      if (networkError) {
        console.debug(
          `[Apollo Network error]: message:${
            networkError.message
          }, statusCode:${(networkError as any).statusCode}`
        );

        // Expired
        if ((networkError as any).statusCode === 401) {
          console.debug(`network error 401, fetch new token and retry`);

          return fetchNewTokenAndRetry(apolloClient, operation, forward);
        }

        // Maintenance mode
        if ((networkError as any).statusCode === 512) {
          setMaintenanceMode(true);
        }
      }
    }
  );

  const authLink = setContext(
    ({ operationName }, { headers: contextHeaders }) => {
      // Some GraphQL operations must not include auth information
      if (!operationName) {
        return {};
      }

      const mustSkipAuthentication =
        SKIP_AUTHENTICATION_WHITELIST.includes(operationName);

      if (mustSkipAuthentication) {
        return {};
      }

      return {
        headers: {
          ...contextHeaders,
          "X-Company-Profile-Id": getCompanyProfileId() ?? ssrCompanyProfileId,
        },
      };
    }
  );

  return new ApolloClient({
    cache: new InMemoryCache(),
    credentials: "include",
    connectToDevTools: process.env.ENVIRONMENT !== "production",
    link:
      typeof window === "undefined"
        ? ApolloLink.from([
            new SSRMultipartLink({
              stripDefer: true,
            }),
            authLink,
            httpLink,
          ])
        : ApolloLink.from([errorLink, authLink, httpLink]),
  });
}

type Props = {
  companyProfileId?: string;
};

export function ApolloProvider({
  children,
  companyProfileId,
}: React.PropsWithChildren<Props>) {
  return (
    <ApolloNextAppProvider makeClient={() => makeClient(companyProfileId)}>
      {children}
    </ApolloNextAppProvider>
  );
}
